import React, { useState, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import FormControl from "react-bootstrap/FormControl";
import { BsSearch, BsFillBackspaceFill } from "react-icons/bs";
import { useLocation, Link } from "react-router-dom";
import { useChat } from "../contexts/ChatProvider";

function Navigation({
  setToken,
  setName,
  searchText,
  setSearchText,
  searchInputFieldValue,
  setSearchInputFieldValue,
}) {
  const [expanded, setExpanded] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const { connected } = useChat();

  function onClickLogout(e) {
    e.preventDefault();
    setToken(null); // TODO: provide a centralized version of "logout" function, as it is needed in multiple places
    setName(null);
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    setSearchText(searchInputFieldValue);
    setTimeout(() => {
      setExpanded(false);
    }, 150);
  }

  function resetSearchHandler(e) {
    e.preventDefault();
    const searchTextBefore = searchText;
    setSearchInputFieldValue("");
    setSearchText("");
    // only close burger menu if a search had been active before clicking the reset button
    if (searchTextBefore) {
      setTimeout(() => {
        setExpanded(false);
      }, 150);
    }
  }

  const location = useLocation();
  const locationsWithDisabledInputs = ["/settings", "/uploads"];

  return (
    <Navbar bg="primary" variant="dark" expand="md" expanded={expanded}>
      <Container>
        <Navbar.Brand href="/">Le Chat 2.0</Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              onClick={() =>
                setTimeout(() => {
                  setExpanded(false);
                }, 150)
              }
              to="/"
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() =>
                setTimeout(() => {
                  setExpanded(false);
                }, 150)
              }
              to="/settings"
            >
              Settings
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={() =>
                setTimeout(() => {
                  setExpanded(false);
                }, 150)
              }
              to="/uploads"
            >
              Uploads
            </Nav.Link>
            <Nav.Link onClick={onClickLogout}>Logout</Nav.Link>
          </Nav>
          <Form className="d-flex" onSubmit={handleSearchSubmit}>
            <InputGroup id="searchInput" className="m-1">
              <FormControl
                type="search"
                placeholder="Search Messages"
                aria-label="Search Messages"
                id="search-input"
                value={searchInputFieldValue}
                onChange={(e) => {
                  setSearchInputFieldValue(e.target.value);
                  if (!e.target.value) resetSearchHandler(e);
                }}
                disabled={
                  isSearching ||
                  locationsWithDisabledInputs.includes(location.pathname) ||
                  !connected
                }
              />
              <Button
                type="submit"
                disabled={
                  isSearching ||
                  locationsWithDisabledInputs.includes(location.pathname) ||
                  !connected
                }
              >
                {isSearching ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <BsSearch />
                )}
              </Button>
              <Button
                onClick={resetSearchHandler}
                disabled={
                  isSearching ||
                  locationsWithDisabledInputs.includes(location.pathname) ||
                  !connected
                }
              >
                <BsFillBackspaceFill />
              </Button>
            </InputGroup>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
