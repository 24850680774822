import React, { useContext, useEffect } from "react";

import useLocalStorage from "../hooks/useLocalStorage";

const SettingsContext = React.createContext();

export function useSettings() {
  return useContext(SettingsContext);
}

export function SettingsProvider({ children }) {
  const defaultSettings = {
    isDarkMode: false,
    isPushSubscribed: false,
  };
  const [settings, setSettings] = useLocalStorage("settings", defaultSettings);

  useEffect(() => {
    document.documentElement.setAttribute(
      "data-bs-theme",
      settings.isDarkMode ? "dark" : "light"
    );
  }, [settings.isDarkMode]);

  const value = { settings, setSettings };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
