import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

export default function AppInstallationInstructions() {
  const [collapseIsUnfolded, setCollapseUnfolded] = useState({
    "... on iPhone": false,
    "... on Android": false,
    "Google Play Store": false,
    "Android Firefox": false,
    "Android Chrome": false,
    "Android Samsung Internet Browser": false,
    "... on Windows": false,
  });

  return (
    <>
      <h3>
        ... on iPhone
        <Button
          variant="link"
          size="sm"
          onClick={() =>
            setCollapseUnfolded({
              ...collapseIsUnfolded,
              "... on iPhone": !collapseIsUnfolded["... on iPhone"],
            })
          }
        >
          show/hide
        </Button>
      </h3>
      <Collapse in={collapseIsUnfolded["... on iPhone"]}>
        <p>
          Navigate to{" "}
          <a href="http://www.lechat2.com">http://www.lechat2.com</a> in Safari.
          Then tap the ‘Share’ button, scroll down and tap ‘Add to Home Screen.’
          Enter the name for the app ("Le Chat 2.0"), then tap add. The Le Chat
          app will show up on your home screen like a native iOS app.
        </p>
      </Collapse>
      <h3>
        ... on Android
        <Button
          variant="link"
          size="sm"
          onClick={() =>
            setCollapseUnfolded({
              ...collapseIsUnfolded,
              "... on Android": !collapseIsUnfolded["... on Android"],
            })
          }
        >
          show/hide
        </Button>
      </h3>
      <Collapse in={collapseIsUnfolded["... on Android"]}>
        <div style={{ marginLeft: "20px" }}>
          <h4>
            Google Play Store
            <Button
              variant="link"
              size="sm"
              onClick={() =>
                setCollapseUnfolded({
                  ...collapseIsUnfolded,
                  "Google Play Store": !collapseIsUnfolded["Google Play Store"],
                })
              }
            >
              show/hide
            </Button>
          </h4>
          <Collapse in={collapseIsUnfolded["Google Play Store"]}>
            <p>Coming soon...</p>
          </Collapse>
          <h4>
            Android Firefox
            <Button
              variant="link"
              size="sm"
              onClick={() =>
                setCollapseUnfolded({
                  ...collapseIsUnfolded,
                  "Android Firefox": !collapseIsUnfolded["Android Firefox"],
                })
              }
            >
              show/hide
            </Button>
          </h4>
          <Collapse in={collapseIsUnfolded["Android Firefox"]}>
            <p>
              Navigate to{" "}
              <a href="http://www.lechat2.com">http://www.lechat2.com</a> in
              Firefox for Android. Then tap the three dots and select "install"
              and follow the instructions.
            </p>
          </Collapse>
          <h4>
            Android Chrome
            <Button
              variant="link"
              size="sm"
              onClick={() =>
                setCollapseUnfolded({
                  ...collapseIsUnfolded,
                  "Android Chrome": !collapseIsUnfolded["Android Chrome"],
                })
              }
            >
              show/hide
            </Button>
          </h4>
          <Collapse in={collapseIsUnfolded["Android Chrome"]}>
            <p>
              Navigate to{" "}
              <a href="http://www.lechat2.com">http://www.lechat2.com</a> in
              Chrome for Android. Then tap the three dots and select "Add to
              home screen" and follow the instructions.
            </p>
          </Collapse>
          <h4>
            Android Samsung Internet Browser
            <Button
              variant="link"
              size="sm"
              onClick={() =>
                setCollapseUnfolded({
                  ...collapseIsUnfolded,
                  "Android Samsung Internet Browser":
                    !collapseIsUnfolded["Android Samsung Internet Browser"],
                })
              }
            >
              show/hide
            </Button>
          </h4>
          <Collapse in={collapseIsUnfolded["Android Samsung Internet Browser"]}>
            <p>
              Navigate to{" "}
              <a href="http://www.lechat2.com">http://www.lechat2.com</a> in the
              "Samsung Internet" browser. Then tap the three stripes and select
              "Add to ..." and then "home screen" and follow the instructions.
            </p>
          </Collapse>
        </div>
      </Collapse>
      <h3>
        ... on Windows
        <Button
          variant="link"
          size="sm"
          onClick={() =>
            setCollapseUnfolded({
              ...collapseIsUnfolded,
              "... on Windows": !collapseIsUnfolded["... on Windows"],
            })
          }
        >
          show/hide
        </Button>
      </h3>
      <Collapse in={collapseIsUnfolded["... on Windows"]}>
        <p>
          Navigate to{" "}
          <a href="http://www.lechat2.com">http://www.lechat2.com</a> in Chrome.
          <br />
          Click the button on the right of the URL to install the app locally in
          Windows. <br />
          <img
            src="/images/other/install-app-windows-chrome.png"
            width="274"
            height="33"
            alt="Install App On Windows Chrome"
          />
        </p>
      </Collapse>
    </>
  );
}
