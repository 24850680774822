import React, { useRef, useState, useEffect } from "react";
import { useChat } from "../contexts/ChatProvider";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import CloseButton from "react-bootstrap/CloseButton";
import { BsUpload, BsFillEmojiSmileFill, BsFillEnvelopeFill } from "react-icons/bs";
import EmotePickerButton from "./EmotePickerButton";
import { useLocation } from "react-router-dom";
import { bbcode } from "../functions/bbcode";

function Userinput({ setToken, setName }) {
  const [messageText, setMessageText] = useState("");
  const [file, setFile] = useState("");
  const [isUploading, setUploading] = useState(false);
  const [showEmotes, setShowEmotes] = useState(false);

  const handleCloseEmotes = () => setShowEmotes(false);
  const handleShowEmotes = () => setShowEmotes(true);
  const { sendMessage, uploadFile, connected, isReplyingTo, setReplyingTo } =
    useChat();

  const location = useLocation();
  const locationsWithDisabledInputs = ["/settings", "/uploads"];

  const messageInputRef = useRef();
  const uploadInputRef = useRef();

  function onMessageSubmit(e) {
    e.preventDefault();
    sendMessage(messageText);
    setMessageText("");
    handleCloseEmotes();
  }

  function handleUploadClick() {
    uploadInputRef.current?.click();
  }

  function logout() {
    setToken(null); // TODO: provide a centralized version of "logout" function, as it is needed in multiple places
    setName(null);
  }

  function handleFileUploadSubmit() {
    setUploading(true);
    // TODO: error handling if no file is selected
    const formData = new FormData();
    formData.append("file", file);
    uploadFile(formData)
      .then((fileInformation) => {
        const uploadMessage = encodeURI(
          `${process.env.REACT_APP_BACKEND_URI}/uploads/${fileInformation.filename}`
        );
        sendMessage(uploadMessage);
        // form.reset();
        setUploading(false);
      })
      .catch((error) => {
        if (error.response.data.message) {
          if (error.response.data.message == "TokenExpiredError: jwt expired") {
            alert(
              "Your authentication token has expired. Please log in again."
            );
            logout();
          } else {
            alert(error.response.data.message); // if available, show the error message
          }
        } else if (error.response && error.response.statusText) {
          alert(error.response.statusText); // otherwise only show the status text
        } else if (error.response && error.response.status == 413) {
          alert("File too large");
        } else {
          alert("Something went wrong..."); // fallback: show a generic error message
        }
        setUploading(false);
      });
  }

  function insertAtCursor(textInsert) {
    // const myField = messageInputRef.current;
    // myField.focus();
    // TODO: make sure that the insertion takes place at the cursor; this was buggy last time it was tried
    setMessageText(messageText + textInsert);
  }

  useEffect(() => {
    if (file) handleFileUploadSubmit();
    // if (file) alert(file);
  }, [file]);

  const replyBaseInfoComponent = isReplyingTo ? (
    <Card>
      <CloseButton
        onClick={() => setReplyingTo(null)}
        aria-label="Reset reply mode"
      />
      <p>
        Replying to {isReplyingTo.user.name} @{" "}
        {new Date(isReplyingTo.createdAt).toLocaleString()}
      </p>
      <p>{bbcode(isReplyingTo.text)}</p>
    </Card>
  ) : null;

  return (
    <>
      {replyBaseInfoComponent}
      <Form onSubmit={onMessageSubmit}>
        <InputGroup id="userInput">
          <FormControl
            ref={messageInputRef}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            placeholder="Your message"
            autoFocus
            autoComplete="off"
            id="message-input"
            as="textarea"
            style={{ resize: "none" }}
            rows={2}
            disabled={
              locationsWithDisabledInputs.includes(location.pathname) ||
              !connected
            }
          />
          {/* https://medium.com/codex/use-a-button-to-upload-files-on-your-react-app-with-bootstrap-ef963cbe8280 */}

          <input
            onChange={(e) => setFile(e.target.files[0])}
            ref={uploadInputRef}
            className="d-none"
            type="file"
            accept="image/png, image/gif, image/jpeg"
          />
          <Button
            onClick={handleUploadClick}
            aria-label="Upload File"
            disabled={
              isUploading ||
              locationsWithDisabledInputs.includes(location.pathname) ||
              !connected
            }
          >
            {isUploading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <BsUpload />
            )}
          </Button>
          <EmotePickerButton
            insertAtCursor={insertAtCursor}
            showEmotes={showEmotes}
            setShowEmotes={setShowEmotes}
            handleShowEmotes={handleShowEmotes}
            handleCloseEmotes={handleCloseEmotes}
            aria-label="Pick Emotes"
            disabled={
              locationsWithDisabledInputs.includes(location.pathname) ||
              !connected
            }
          >
            <BsFillEmojiSmileFill />
          </EmotePickerButton>
          <Button
            type="submit"
            disabled={
              locationsWithDisabledInputs.includes(location.pathname) ||
              !connected
            }
          >
            <BsFillEnvelopeFill />
          </Button>
        </InputGroup>
      </Form>
    </>
  );
}

export default Userinput;
