import React from "react";
import { useSettings } from "../../contexts/SettingsProvider";

export default function DarkModeSwitch() {
  const { settings, setSettings } = useSettings();

  const switchCheckBox = () => {
    setSettings({ ...settings, isDarkMode: !settings.isDarkMode });
  };

  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
        checked={settings.isDarkMode}
        onChange={switchCheckBox}
      />
      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
        Dark Mode Switch
      </label>
    </div>
  );
}
