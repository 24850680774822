import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

export default function Login({ setToken, setName }) {
  const [loginName, setLoginName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  // TODO: Login Form Validation using frontend and backend, see https://react-bootstrap.github.io/components/forms/#forms-validation-input-group

  const onLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/login`,
        {
          name: loginName,
          password: loginPassword,
        },
        config
      );
      const { name, token } = data;
      // alert("Login successful");
      setToken(token);
      setName(name);
    } catch (error) {
      try {
        alert(error.response.data.message);
      } catch (error2) {
        alert(error);
      }
    }
  };

  return (
    <div className="Login">
      <Form onSubmit={onLoginSubmit}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            placeholder="Username"
            onChange={(e) => setLoginName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Password"
            onChange={(e) => setLoginPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
