import React, { useState } from "react";
import axios from "axios";
import { useChat } from "../../contexts/ChatProvider";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function ChangePasswordForm({ token }) {
  const { connected } = useChat();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const onChangePasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== newPasswordConfirm) {
      alert("Password and confirmed password are not equal");
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/changePassword`,
        {
          newPassword,
        },
        config
      );
      const { message } = data;
      alert(message);
    } catch (error) {
      try {
        alert(error.response.data.message);
      } catch (error2) {
        alert(error);
      }
    }
  };
  return (
    <Form onSubmit={onChangePasswordSubmit}>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          placeholder="Password"
          onChange={(e) => setNewPassword(e.target.value)}
          disabled={!connected}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="passwordConfirm">
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
          type="password"
          name="passwordConfirm"
          placeholder="Password"
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
          disabled={!connected}
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={!connected}>
        Change Password
      </Button>
    </Form>
  );
}
