import React, { useEffect } from "react";
import { useChat } from "../contexts/ChatProvider";
import { bbcode } from "../functions/bbcode";

function Uploads() {
  const {
    uploads,
    // links,
    loadUploadsAndLinks,
  } = useChat();

  useEffect(() => {
    loadUploadsAndLinks();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <h2>Uploads</h2>
      <ul style={{ wordWrap: "break-word" }}>
        {uploads.map(
          ({ name, filename, originalFilename, createdAt }, index) => (
            <li key={index}>
              <a
                href={`${process.env.REACT_APP_BACKEND_URI}/uploads/${filename}`}
              >
                {originalFilename ? originalFilename : filename}
              </a>
              <br /> (uploaded by {name} @{" "}
              {new Date(createdAt).toLocaleString()})
            </li>
          )
        )}
      </ul>
      {/* <h2>Links</h2>
      <ul style={{ wordWrap: "break-word" }}>
        {links.map(({ name, text, createdAt }, index) => (
          <li key={index}>
            <span>{bbcode(text, true)}</span>
            <br /> (shared by {name} @ {new Date(createdAt).toLocaleString()})
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default Uploads;
