import React from "react";
import { useUsers } from "../contexts/UsersProvider";
import { useChat } from "../contexts/ChatProvider";
import Spinner from "react-bootstrap/Spinner";
import CloseButton from "react-bootstrap/CloseButton";

function Infobar({
  currentUserName,
  searchText,
  setSearchText,
  setSearchInputFieldValue,
}) {
  const { users } = useUsers();
  const { connected } = useChat();

  let usersComponent = (
    <h2>
      Users online:
      {users.map((name, index) => (
        <span key={index}>
          {index > 0 && ", "} {name == currentUserName ? `Du (${name})` : name}
        </span>
      ))}
    </h2>
  );

  let searchComponent = (
    <h2>
      Active Search: {searchText}{" "}
      <CloseButton
        onClick={() => {
          setSearchText("");
          setSearchInputFieldValue("");
        }}
      />
    </h2>
  );

  const offlineComponent = (
    <h2>
      Connecting... <Spinner animation="border" />
    </h2>
  );

  return (
    <>
      {connected
        ? searchText
          ? searchComponent
          : usersComponent
        : offlineComponent}
    </>
  );
}

export default Infobar;
