import React, { useRef, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Message from "./Message";
import { useChat } from "../contexts/ChatProvider";

function Chat({ searchText }) {
  const {
    connected,
    messages,
    messageStorage,
    sendLikeUpdate,
    loadMessages,
    reloadMessages,
    searchMessages,
    reloadSearchMessages,
    chatSettings,
  } = useChat();
  const latestMessageRef = useRef();
  const oldestMessageRef = useRef();

  const [isReLoading, setReLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSending, setSending] = useState(false);

  function updateLike(e, messageId, isLikedByUser) {
    sendLikeUpdate(messageId, !isLikedByUser); // invert the boolean to invert the current state
  }

  function onLoadMessagesSubmit(e) {
    // TODO: make the "reloading" status work
    e.preventDefault();
    setReLoading(true);
    searchText
      ? reloadSearchMessages(
          searchText,
          chatSettings.numberOfSearchResultsOnReload,
          oldestMessageRef.current.id
        )
      : reloadMessages(
          chatSettings.numberOfMessagesOnReload,
          oldestMessageRef.current.id
        );
    setReLoading(false);
  }

  useEffect(() => {
    searchText
      ? searchMessages(searchText, chatSettings.numberOfSearchResults)
      : loadMessages(chatSettings.numberOfMessagesOnLoad);
    // alert(`Searching for ${searchText}`);
  }, [searchText]); // in order to remove a warning, the dependencies "searchMessages" and "loadMessages" should be added to the dependencies here; but this makes the effect run every millisecond..?!

  useEffect(() => {
    // reset app badge on chat render
    if (navigator.clearAppBadge) {
      navigator.clearAppBadge();
    }
  }, []);

  const loadOlderMessagesButton = (
    <tr key="load-older-messages-button-1234567890">
      <td colSpan="3" align="center">
        <Button
          variant="outline-primary"
          size="sm"
          disabled={isReLoading}
          onClick={onLoadMessagesSubmit}
        >
          {isReLoading
            ? "Loading…"
            : searchText
            ? "Load more results"
            : "Load older messages"}
        </Button>
      </td>
    </tr>
  );

  let messageComponents = messages.map((message, index) => {
    const isLastMessage = messages.length - 1 === index;
    const isFirstMessage = index === 0;
    const setRef = isLastMessage
      ? latestMessageRef
      : isFirstMessage
      ? oldestMessageRef
      : null;
    return (
      <Message
        key={index}
        ref={setRef}
        message={message}
        updateLike={updateLike}
        searchText={searchText}
      />
    );
  });

  let CachedMessageComponents = messageStorage.map((message, index) => {
    const isLastMessage = messages.length - 1 === index;
    const isFirstMessage = index === 0;
    const setRef = isLastMessage
      ? latestMessageRef
      : isFirstMessage
      ? oldestMessageRef
      : null;
    return (
      <Message
        key={index}
        ref={setRef}
        message={message}
        updateLike={updateLike}
        searchText={searchText}
      />
    );
  });

  const offlineMessageComponent = (
    // TODO: Placeholder for Skeleton Loader, e.g. https://codepen.io/taylorgorman/pen/GRojZwV
    // <Message
    //   key={0}
    //   ref={oldestMessageRef}
    //   message={{
    //     _id: "abc",
    //     createdAt: new Date(),
    //     islikedByUser: true,
    //     likeCount: 1337,
    //     text: ":cosbytroll:".repeat(10),
    //     user: { name: "Offline Troll" },
    //   }}
    //   updateLike={() => {
    //     return;
    //   }}
    // />
    <></>
  );

  return (
    <Table striped hover id="chatTable">
      <tbody>
        {connected
          ? [loadOlderMessagesButton, messageComponents]
          : messageStorage.length > 0
          ? CachedMessageComponents
          : offlineMessageComponent}
      </tbody>
    </Table>
  );
}

export default Chat;
