import React, { useEffect } from "react";
import axios from "axios";
import { useSettings } from "../../contexts/SettingsProvider";
import { BsFillBellFill, BsFillBellSlashFill } from "react-icons/bs";

export default function PushSubscriptionButton({ token }) {
  const { settings, setSettings } = useSettings();

  const requestNotificationPermission = () => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser / device may not support notifications");
    }
    Notification.requestPermission(function (status) {
      // console.log("Notification permission status:", status);
    });
  };

  const onSwitchPushSubscriptionCheckBox = () => {
    if (!settings.isPushSubscribed) {
      // alert("Subscribe");
      if (!("serviceWorker" in navigator)) {
        console.log("Cannot subscribe, no service worker found.");
        alert(
          "Could not subscribe. Make sure to install Le Chat 2.0 on your homescreen first."
        );
        setSettings({
          ...settings,
          isPushSubscribed: false,
        });
        return;
      }
      requestNotificationPermission();
      setSettings({
        ...settings,
        isPushSubscribed: true,
      });
    } else {
      // alert("Unsubscribe");
      setSettings({
        ...settings,
        isPushSubscribed: false,
      });
    }
  };

  const sendSubscriptionToServer = async (data) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios.put(
        `${process.env.REACT_APP_BACKEND_URI}/subscription`,
        data,
        config
      );
      return resp.data;
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  const deleteSubscriptionFromServer = async (data) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/subscription`,
        { headers: config.headers, data }
      );
      return resp.data;
    } catch (error) {
      alert(error);
      throw error;
    }
  };

  useEffect(() => {
    if ("Notification" in window && "serviceWorker" in navigator) {
      // All the subscription handling is to be done here
      if (settings.isPushSubscribed) {
        // console.log(`Subscribtion Procedure...`);
        navigator.serviceWorker.ready.then((reg) => {
          reg.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
            })
            .then((sub) => {
              console.log("Successfully subscribed.");
              console.log(sub);
              // alert(sub.endpoint);
              var data = sub.toJSON();
              sendSubscriptionToServer(data).then((x) => {});
            })
            .catch((e) => {
              // alert("Subscribing locally failed.");
              // alert(e);
            });
        });
      } else {
        // console.log(`Unsubscription Procedure...`);
        // alert(`Unsubscription Procedure...`);
        navigator.serviceWorker.ready.then((reg) => {
          reg.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
            })
            .then((sub) => {
              console.log("Successfully got subscription object.");
              console.log(sub);
              // alert(sub.endpoint);
              var data = sub.toJSON();
              deleteSubscriptionFromServer(data).then((x) => console.log(x));
            })
            .catch((e) => {
              // alert("Deleting subscription from server failed.");
              // alert(e);
            });
        });
      }
    } else {
    }
  }, [settings.isPushSubscribed]);

  let notificationStatusElement;
  if ("Notification" in window) {
    if (Notification.permission === "granted") {
      notificationStatusElement = (
        <>
          <BsFillBellFill color="green" /> Notification Permission Granted By
          Device
        </>
      );
    } else if (Notification.permission === "denied") {
      notificationStatusElement = (
        <>
          <BsFillBellSlashFill color="red" /> Notification Permission Denied By
          Device
        </>
      );
    } else if (Notification.permission === "default") {
      notificationStatusElement = (
        <>
          <BsFillBellFill color="orange" /> Notification Permission not
          requested
        </>
      );
    } else {
      notificationStatusElement = (
        <>
          <BsFillBellSlashFill color="gray" /> Notification Permission status
          unknown
        </>
      );
    }
  } else {
    notificationStatusElement = (
      <>
        <BsFillBellSlashFill color="red" /> Notifications not supported in this
        browser
      </>
    );
  }

  return (
    <>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="pushSubscriptionRadioButton"
          checked={settings.isPushSubscribed}
          onChange={onSwitchPushSubscriptionCheckBox}
        />
        <label
          className="form-check-label"
          htmlFor="pushSubscriptionRadioButton"
        >
          Subscribe for Push Notifications
        </label>
      </div>
      <p>{notificationStatusElement}</p>
    </>
  );
}
