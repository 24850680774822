import React, { useContext, useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useSocket } from "./SocketProvider";
import useLocalStorage from "../hooks/useLocalStorage";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";

const ChatContext = React.createContext();

export function useChat() {
  return useContext(ChatContext);
}

export function ChatProvider({
  token,
  setToken,
  setName,
  children,
  scrollToBottom,
  scrollIsLockedToBottom,
  setScrollIsLockedToBottom,
  searchText,
}) {
  const [messages, setMessages] = useState([]);
  const [connected, setConnected] = useState(false);
  const [isReplyingTo, setReplyingTo] = useState(null);
  const [messageStorage, setMessageStorage] = useLocalStorage("messages", []);
  const socket = useSocket();

  const [uploads, setUploads] = useState([]);
  // const [links, setLinks] = useState([]);

  const logout = () => {
    setToken(null); // TODO: provide a centralized version of "logout" function, as it is needed in multiple places
    setName(null);
  };

  const chatSettings = {
    numberOfMessagesOnLoad: 30,
    numberOfMessagesOnReload: 20,
    numberOfSearchResults: 30,
    numberOfSearchResultsOnReload: 20,
  };

  const addMessageToChat = useCallback((message) => {
    // TODO: only show notifications if window is not in focus
    if (!searchText) {
      // reset app badge when a new message has been successfully rendered
      if (navigator.clearAppBadge) {
        navigator.clearAppBadge();
      }
      setMessages((prevMessages) => {
        return [...prevMessages, message];
      });
    }
  }); // TODO: add dependency array to useCallback to avoid warning

  const updateMessageLikes = useCallback(
    ({ messageId, newLikeCount, newIsLikedByUser }) => {
      // if this function is called from a socket event, it receives {messageId, newLikeCount}; likedByUser stays the same in this case (gets undefined)
      // if this function is called from a  like update event of the local user, it receives {messageId, newIsLikedByUser}; newLikeCount is calculated locally
      setMessages((prevMessages) => {
        const messagesNew = prevMessages.map((message) => {
          // return old message, if the messageId doest not match
          if (message._id !== messageId) return message;
          // update isLikedByUser and increment/decrement likeCount if the event was called locally
          if (
            typeof newIsLikedByUser !== "undefined" &&
            newIsLikedByUser != message.isLikedByUser
          ) {
            const newMessage = {
              ...message,
              isLikedByUser: newIsLikedByUser,
              likeCount: newIsLikedByUser
                ? message.likeCount + 1
                : message.likeCount - 1,
            };
            return newMessage;
          }
          // update the likeCount if the event was triggered by socket
          if (typeof newLikeCount !== "undefined") {
            const newMessage = {
              ...message,
              likeCount: newLikeCount,
            };
            return newMessage;
          }
        });
        return messagesNew;
      });
    }
  );

  const prependMessagesToChat = useCallback((messages) => {
    setMessages((prevMessages) => messages.concat(prevMessages));
  }); // TODO: add dependency array to useCallback to avoid warning

  const fetchMessages = async (numberofmessages, beforeid) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/messages/${numberofmessages}/${beforeid}`, // instead of "latest" pass id (e.g. 61b23a73b319824e3c20c1c3) for loading of older messages
        config
      );
      // the date objects have been "json stringified" by express, so need to be reformatted to date objects
      const dataReformatted = resp.data.map((message) => {
        let messageReformatted = message;
        messageReformatted.createdAt = Date.parse(message.createdAt);
        return messageReformatted;
      });
      return dataReformatted;
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status == 403) {
        alert("Your authentication token has expired. Please log in again.");
        logout();
      }
      return []; // returning empty list on error to avoid crashing due to "undefined"
    }
  };

  const fetchMessagesSearch = async (
    searchtext,
    numberofmessages,
    beforeid
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URI
        }/messages/search/${encodeURIComponent(
          searchtext
        )}/${numberofmessages}/${beforeid}`,
        config
      );
      // the date objects have been "json stringified" by express, so need to be reformatted to date objects
      const dataReformatted = resp.data.map((message) => {
        let messageReformatted = message;
        messageReformatted.createdAt = Date.parse(message.createdAt);
        return messageReformatted;
      });
      return dataReformatted;
    } catch (error) {
      // console.error(error);
      return []; // returning empty list on error to avoid crashing due to "undefined"
      // TODO: in all requests to HTTP or websockets, where possibly a "invalid token" error could be returned, this must lead to deletion of the localStorage (= logout)
      // setToken(null);
      // setName(null);
    }
  };

  const fetchUploadsAndLinks = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/uploadsAndLinks`,
        config
      );
      // the date objects have been "json stringified" by express, so need to be reformatted to date objects
      const uploadsReformatted = resp.data.uploads.map((upload) => {
        let uploadReformatted = upload;
        uploadReformatted.createdAt = Date.parse(upload.createdAt);
        return uploadReformatted;
      });
      // const linksReformatted = resp.data.links.map((link) => {
      //   let linkReformatted = link;
      //   linkReformatted.createdAt = Date.parse(link.createdAt);
      //   return linkReformatted;
      // });
      return {
        data: {
          uploads: uploadsReformatted,
          // links: linksReformatted
        },
      };
    } catch (error) {
      // console.error(error);
      if (error.response && error.response.status == 403) {
        alert("Your authentication token has expired. Please log in again.");
        logout();
      }
      return []; // returning empty list on error to avoid crashing due to "undefined"
    }
  };

  useEffect(() => {
    if (socket == null) return;
    socket.on("receive-message", addMessageToChat);
    socket.on("receive-like", updateMessageLikes);
    socket.on("connect", () => {
      setConnected(true);
      if (navigator.clearAppBadge) {
        navigator.clearAppBadge();
      }
      loadMessages(chatSettings.numberOfMessagesOnLoad);
      loadUploadsAndLinks();
    });
    socket.on("connect_error", (err) => {
      if (err.message == "jwt expired") {
        // alert(err.message); // prints the message associated with the error
        alert("Your authentication token has expired. Please log in again.");
        logout();
      } else {
        // alert(`Connection error: '${err.message}'. Please log in again.`);
        // on connect_error, no alert is necessary; the user already has the "Connecting..." feedback in the UI
      }
      // throw err;
    });
    socket.on("disconnect", () => {
      setConnected(false);
    });
    return () => {
      socket.off("receive-message");
      socket.off("receive-like");
      socket.off("connect");
      socket.off("connect_error");
    };
  }, [socket, addMessageToChat, updateMessageLikes]);

  useEffect(() => {
    // const [messageStorage, setMessageStorage] = useLocalStorage("messages", null);
    if(messages.length > 0) {
      setMessageStorage(messages)
    }
  }, [messages])

  useEffect(() => {
    if (scrollIsLockedToBottom) scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // reset App badge when client visibility changes to "visible"
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (navigator.clearAppBadge) {
          navigator.clearAppBadge();
        }
      }
    });
  });

  function sendMessage(text) {
    if (text.trim() == "") return;
    socket.emit("send-message", { text, isReplyingTo });
    setReplyingTo(null);
    setScrollIsLockedToBottom(true); // if you write a message yourself, you would expect the window to scroll down to the new message
    // addMessageToChat({ _id: null, createdAt: Date.now(), name, text }); // actually, I may decide to not add own messages directly but instead wait for them to be emitted back by server
  }

  function sendLikeUpdate(messageId, newBoolValue) {
    socket.emit("send-like", { messageId, newBoolValue });
    updateMessageLikes({ messageId, newIsLikedByUser: newBoolValue }); // actually, I may decide to not add own likes directly but instead wait for them to be emitted back by server
  }

  function loadMessages(numberofmessages) {
    fetchMessages(numberofmessages, "latest").then((messagesFromServer) => {
      setMessages(messagesFromServer);
    });
  }

  function reloadMessages(numberofmessages, beforeid) {
    fetchMessages(numberofmessages, beforeid).then((messagesFromServer) => {
      prependMessagesToChat(messagesFromServer);
    });
  }

  function searchMessages(searchtext, numberofmessages) {
    fetchMessagesSearch(searchtext, numberofmessages, "latest").then(
      (messagesFromServer) => {
        setMessages(messagesFromServer);
      }
    );
  }

  function reloadSearchMessages(searchtext, numberofmessages, beforeid) {
    fetchMessagesSearch(searchtext, numberofmessages, beforeid).then(
      (messagesFromServer) => {
        prependMessagesToChat(messagesFromServer);
      }
    );
  }

  function loadUploadsAndLinks() {
    fetchUploadsAndLinks().then((data) => {
      setUploads(data.data.uploads);
      // setLinks(data.data.links);
    });
  }

  const uploadFile = async (formData) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios.put(
        `${process.env.REACT_APP_BACKEND_URI}/upload`,
        formData,
        config
      );
      return resp.data;
    } catch (error) {
      throw error;
      // TODO: in all requests to HTTP or websockets, where possibly a "invalid token" error could be returned, this must lead to deletion of the localStorage (= logout)
      // TODO: distinguish between normal errors and auth errors
      // setToken(null);
      // setName(null);
    }
  };

  const value = {
    connected,
    isReplyingTo,
    setReplyingTo,
    messageStorage,
    setMessageStorage,
    chatSettings,
    messages,
    uploads,
    // links,
    sendMessage,
    loadMessages,
    reloadMessages,
    searchMessages,
    reloadSearchMessages,
    sendLikeUpdate,
    uploadFile,
    loadUploadsAndLinks,
  };
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
