import React from "react";
import { BsFillEmojiSmileFill, BsReplyFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { bbcode, highlightText } from "../functions/bbcode";
import { useChat } from "../contexts/ChatProvider";

// TODO: highlight new messages (let them flash)

const Message = React.forwardRef(
  ({ index, message, updateLike, searchText }, ref) => {
    const { isReplyingTo, setReplyingTo } = useChat();
    const setReplyHandler = () => {
      setReplyingTo(message);
      document.getElementById("message-input").focus();
    };
    let touchStartX = 0;
    let touchEndX = 0;
    let touchStartY = 0;
    let touchEndY = 0;
    let isDoubleTap = false;
    const handleTouchStart = (e) => {
      // the doubleclick event does not exist on mobile, so it has to be covered in the onTouchStart
      if (!isDoubleTap) {
        isDoubleTap = true;
        setTimeout(function () {
          isDoubleTap = false;
        }, 300);
        touchStartX = e.changedTouches[0].screenX;
        touchStartY = e.changedTouches[0].screenY;
      } else {
        updateLike(e, message._id, message.isLikedByUser);
      }
    };
    const handleTouchEnd = (e) => {
      touchEndX = e.changedTouches[0].screenX;
      touchEndY = e.changedTouches[0].screenY;
      handleGesture();
    };
    const handleGesture = () => {
      // handle swipe right
      const dx = touchEndX - touchStartX;
      const dy = touchEndY - touchStartY;
      const tan_angle = Math.abs(dy / dx);
      const tan_angle_max = Math.tan((25 * Math.PI) / 180);
      if (dx < 0) {
        return; // swiped left -> ignore
      }
      if (Math.abs(dx) < 50) {
        return; // less than 50 pixels of horizontal movement -> ignore
      }
      if (tan_angle > tan_angle_max) {
        return; // horizontal angle > 25 degrees -> ignore
      }
      setReplyHandler();
    };
    const replyBaseMessageComponent = message.replyBaseMessage ? (
      <>
        <Card>
          <Card.Body>
            <Card.Title style={{ fontSize: "small" }}>
              {/* TODO: do not use the ".name" property, as it is not connected via user id. it reflects the name of the user at the time of posting the message but not necessarily his current name */}
              {highlightText(message.replyBaseMessage.name, searchText)} wrote{" "}
              <small>
                ({new Date(message.replyBaseMessage.createdAt).toLocaleString()}
                )
              </small>
              :
            </Card.Title>
            <Card.Text>
              {highlightText(
                bbcode(message.replyBaseMessage.text, false),
                searchText
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </>
    ) : null;
    return (
      <tr
        key={index}
        ref={ref}
        id={message._id}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {/* Detect doubleclick on mobile: https://stackoverflow.com/a/32761323 */}
        {/* Detect swipe on mobile: https://stackoverflow.com/a/56663695 */}
        <td
          className="text-center"
          style={{ width: "30px", lineHeight: "80%" }}
        >
          <strong>{highlightText(message.user.name, searchText)}</strong>
          <br />
          <span style={{ fontSize: "small" }}>
            {new Date(message.createdAt).toLocaleString()}
          </span>
        </td>
        <td className="messageText">
          {replyBaseMessageComponent}
          {highlightText(bbcode(message.text, false), searchText)}
        </td>
        <td style={{ width: "30px" }}>
          <div style={{ whiteSpace: "nowrap" }}>
            <BsFillEmojiSmileFill
              style={{ cursor: "pointer" }}
              color={message.isLikedByUser ? "red" : ""}
              onClick={(e) => updateLike(e, message._id, message.isLikedByUser)}
            />
            &nbsp;{message.likeCount}
          </div>
          <div>
            <BsReplyFill
              style={{ cursor: "pointer" }}
              onClick={(e) => setReplyHandler()}
            />
          </div>
          {/* <img
        src={
          message.isLikedByUser
            ? "/images/emotes/megusta.gif"
            : "/images/emotes/megusta-grey.png"
        }
        onClick={(e) => updateLike(e, message._id, message.isLikedByUser)}
        style={{ cursor: "pointer" }}
        className="mx-1"
      /> */}
        </td>
      </tr>
    );
  }
);

export default Message;
