import React, { useState, useEffect, useRef } from "react";
import "../custom-bootstrap.scss";
import "../style.css";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navigation from "./Navigation";
import Infobar from "./Infobar";
import Chat from "./Chat";
import Userinput from "./Userinput";
import Login from "./Login";
import Uploads from "./Uploads";
import Settings from "./Settings";

import useLocalStorage from "../hooks/useLocalStorage";

import { SettingsProvider } from "../contexts/SettingsProvider";
import { SocketProvider } from "../contexts/SocketProvider";
import { UsersProvider } from "../contexts/UsersProvider";
import { ChatProvider } from "../contexts/ChatProvider";

function App() {
  const [token, setToken] = useLocalStorage("token", null);
  const [name, setName] = useLocalStorage("name", null);
  const [searchText, setSearchText] = useState("");
  const [searchInputFieldValue, setSearchInputFieldValue] = useState("");
  const [scrollIsLockedToBottom, setScrollIsLockedToBottom] = useState(true);
  const scrollRef = useRef();

  function handleScroll(e) {
    // if scrolled all the way down, new messages should also trigger scrolling to bottom
    // let scrollTop = e.target.scrollTop;
    // let scrollTopMax = e.target.scrollHeight - e.target.clientHeight;
    let scrollTop = scrollRef.current.scrollTop;
    let scrollTopMax =
      scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
    setScrollIsLockedToBottom(scrollTop == scrollTopMax);
  }

  function scrollToBottom() {
    let scrollTopMax =
      scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
    scrollRef.current.scrollTop = scrollTopMax;
  }

  const chat = (
    <Router>
      <SettingsProvider>
        <SocketProvider token={token}>
          <UsersProvider>
            <ChatProvider
              token={token}
              setToken={setToken}
              setName={setName}
              scrollToBottom={scrollToBottom}
              scrollIsLockedToBottom={scrollIsLockedToBottom}
              setScrollIsLockedToBottom={setScrollIsLockedToBottom}
              searchText={searchText}
            >
              <Container
                className="vh-100 d-flex flex-column bg-primary"
                style={{ maxWidth: "960px" }}
              >
                <Row className="bg-secondary">
                  <Navigation
                    setToken={setToken}
                    setName={setName}
                    setSearchText={setSearchText}
                    searchText={searchText}
                    setSearchInputFieldValue={setSearchInputFieldValue}
                    searchInputFieldValue={searchInputFieldValue}
                  />
                </Row>
                <Row className="bg-background">
                  <Infobar
                    currentUserName={name}
                    setSearchText={setSearchText}
                    searchText={searchText}
                    setSearchInputFieldValue={setSearchInputFieldValue}
                  />
                </Row>
                <Row
                  onScroll={handleScroll}
                  ref={scrollRef}
                  className="bg-background d-flex flex-column vh-100"
                  style={{
                    overflowY: "scroll",
                    // scrollbarColor: scrollIsLockedToBottom
                    //   ? "black white"
                    //   : "red yellow",
                  }}
                >
                  <Routes>
                    <Route path="/uploads" element={<Uploads />} />
                    <Route
                      path="/settings"
                      element={<Settings token={token} />}
                    />
                  <Route path="*" element={<Chat searchText={searchText} />} />
                  </Routes>
                </Row>
                <Row className="bg-background-dark border-top mt-auto p-3">
                  <Userinput setToken={setToken} setName={setName} />
                </Row>
              </Container>
            </ChatProvider>
          </UsersProvider>
        </SocketProvider>
      </SettingsProvider>
    </Router>
  );

  const login = <Login setToken={setToken} setName={setName} />;

  return token ? chat : login;
}

export default App;
