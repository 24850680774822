import reactStringReplace from "react-string-replace";
import YoutubeEmbed from "../components/YoutubeEmbed";

// const reactStringReplace = require("react-string-replace");

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const emotes = [
  {
    shorthand: ":cosbyokay:",
    imgurl: "/images/emotes/cosbyokay.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbynotbad:",
    imgurl: "/images/emotes/cosbynotbad.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbymotherofgod:",
    imgurl: "/images/emotes/cosbymotherofgod.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyawwyeah:",
    imgurl: "/images/emotes/cosbyawwyeah.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbydisgust:",
    imgurl: "/images/emotes/cosbydisgust.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbypfchch:",
    imgurl: "/images/emotes/cosbypfchch.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbylol:",
    imgurl: "/images/emotes/cosbylol.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbylove:",
    imgurl: "/images/emotes/cosbylove.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbylikeasir:",
    imgurl: "/images/emotes/cosbylikeasir.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyno:",
    imgurl: "/images/emotes/cosbyno.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbychallenge:",
    imgurl: "/images/emotes/cosbychallenge.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyyuno:",
    imgurl: "/images/emotes/cosbyyuno.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyfuckyea:",
    imgurl: "/images/emotes/cosbyfuckyea.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyayfkm:",
    imgurl: "/images/emotes/cosbyayfkm.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbybetter:",
    imgurl: "/images/emotes/cosbybetter.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyforeveralone:",
    imgurl: "/images/emotes/cosbyforeveralone.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyfreak:",
    imgurl: "/images/emotes/cosbyfreak.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbypokerface:",
    imgurl: "/images/emotes/cosbypokerface.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyrage:",
    imgurl: "/images/emotes/cosbyrage.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbysomuchwin:",
    imgurl: "/images/emotes/cosbysomuchwin.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbytroll:",
    imgurl: "/images/emotes/cosbytroll.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbymegusta:",
    imgurl: "/images/emotes/cosbymegusta.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyilied:",
    imgurl: "/images/emotes/cosbyilied.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyfuckthat:",
    imgurl: "/images/emotes/cosbyfuckthat.png",
    width: 96,
    height: 96,
  },
  {
    shorthand: ":cosbyparty:",
    imgurl: "/images/emotes/cosbyparty.png",
    width: 96,
    height: 96,
  },
  // { shorthand: ":wavearm:", imgurl: "~:troll:~" },
  {
    shorthand: ":pdq:",
    imgurl: "/images/emotes/pdq_klein.png",
    width: 83,
    height: 100,
  },
  {
    shorthand: ":brauchsch:",
    imgurl: "/images/emotes/brauchsch.png",
    width: 166,
    height: 100,
  },
  {
    shorthand: ":ficken:",
    imgurl: "/images/emotes/ficken.png",
    width: 124,
    height: 100,
  },
  {
    shorthand: ":gurrgurr:",
    imgurl: "/images/emotes/gurrgurr.png",
    width: 66,
    height: 50,
  },
  {
    shorthand: ":trolldad:",
    imgurl: "/images/emotes/dancingtrolldad.gif",
    width: 73,
    height: 100,
  },
  {
    shorthand: ":hyped:",
    imgurl: "/images/emotes/hyped.gif",
    width: 69,
    height: 100,
  },
  {
    shorthand: ":notbad:",
    imgurl: "/images/emotes/notbad.png",
    width: 100,
    height: 100,
  },
  {
    shorthand: ":ayfkm:",
    imgurl: "/images/emotes/ayfkm.png",
    width: 22,
    height: 21,
  },
  {
    shorthand: ":troll:",
    imgurl: "/images/emotes/coolface.GIF",
    width: 22,
    height: 18,
  },
  {
    shorthand: ":megusta:",
    imgurl: "/images/emotes/megusta.gif",
    width: 18,
    height: 18,
  },
  {
    shorthand: ":foreveralone:",
    imgurl: "/images/emotes/foreveralonet.gif",
    width: 23,
    height: 22,
  },
  {
    shorthand: ":mekiffa:",
    imgurl: "/images/emotes/highnachtsmegusta.png",
    width: 20,
    height: 32,
  },
  {
    shorthand: ":facepalm:",
    imgurl: "/images/emotes/facepalm.gif",
    width: 15,
    height: 15,
  },
  {
    shorthand: ":lol:",
    imgurl: "/images/emotes/loltx.gif",
    width: 20,
    height: 24,
  },
  {
    shorthand: ":rage:",
    imgurl: "/images/emotes/rage.gif",
    width: 37,
    height: 15,
  },
  {
    shorthand: ":pokerface:",
    imgurl: "/images/emotes/pokerface.gif",
    width: 16,
    height: 22,
  },
  {
    shorthand: ":herz:",
    imgurl: "/images/emotes/heart.png",
    width: 13,
    height: 12,
  },
  {
    shorthand: ":pfchch:",
    imgurl: "/images/emotes/masturbatory_smile08qe.gif",
    width: 24,
    height: 22,
  },
  {
    shorthand: ":okay:",
    imgurl: "/images/emotes/okay.gif",
    width: 16,
    height: 23,
  },
  {
    shorthand: ":somuchwin:",
    imgurl: "/images/emotes/somuchwin.gif",
    width: 25,
    height: 27,
  },
  {
    shorthand: ":ilied:",
    imgurl: "/images/emotes/ilied.gif",
    width: 26,
    height: 23,
  },
  {
    shorthand: ":freak:",
    imgurl: "/images/emotes/freak.gif",
    width: 24,
    height: 24,
  },
  {
    shorthand: ":better:",
    imgurl: "/images/emotes/better.gif",
    width: 25,
    height: 19,
  },
  {
    shorthand: ":fuckthat:",
    imgurl: "/images/emotes/fuckthat.png",
    width: 20,
    height: 25,
  },
  {
    shorthand: ":gtfo:",
    imgurl: "/images/emotes/gtfo.png",
    width: 45,
    height: 31,
  },
];

// TODO: this is some VERY basic bbcode just for demo purpose, but later a real library should be used, e.g. https://www.npmjs.com/package/bbcode-to-react

// TODO: also make sure that not multiple features are matched in the same part, e.g. "http://localhost:5000" could also match an "emote" :----:

function ThumbnailImage({ src }) {
  const src_thumbnail = src.startsWith(
    `${process.env.REACT_APP_BACKEND_URI}/uploads/`
  )
    ? src + "?thumbnail=true"
    : src;
  return (
    <a href={src}>
      <img src={src_thumbnail} height={100} alt="Thumbnail" />
    </a>
  );
}

function Emote({ src, shorthand, width, height }) {
  return (
    <img
      src={src}
      height={height ? height : null}
      width={width ? width : null}
      style={{ maxHeight: "100px" }}
      alt={shorthand}
    />
  );
}

function bbcode(text, noImages) {
  let replacedText;
  replacedText = text;

  if (!noImages) {
    // Match image previews
    replacedText = reactStringReplace(
      replacedText,
      /([a-z]{3,5}:\/\/[a-zäöüß0-9.:\-_&%=\/?()]+\.(?:jpg|jpeg|gif|png|bmp))/i,
      (match, i) => {
        // console.log(match);
        return <ThumbnailImage key={match + i} src={match} />;
      }
    );
  }

  // http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?
  if (!noImages) {
    // Match youtube links
    replacedText = reactStringReplace(
      replacedText,
      /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/|be\.com\/shorts\/)(\S+)(?:\?feature=share)?/g,
      (match, i) => {
        console.log(match);
        return <YoutubeEmbed key={match + i} embedId={match} />;
      }
    );
  }

  // Match URLs
  replacedText = reactStringReplace(
    replacedText,
    /(https?:\/\/\S+)/g,
    (match, i) => (
      <a key={match + i} href={match}>
        {match}
      </a>
    )
  );

  // Match @-mentions
  // replacedText = reactStringReplace(
  //   replacedText,
  //   /@(\w+)/g, // TODO: nur existierende User matchen
  //   (match, i) => (
  //     <span key={match + i} style={{ color: "red" }}>
  //       @{match}
  //     </span>
  //   )
  // );

  if (!noImages) {
    // Match emotes
    replacedText = reactStringReplace(replacedText, /(:\w+:)/gi, (match, i) => {
      let emote = emotes.find((e) => e.shorthand === match);
      if (typeof emote === "undefined") return match;
      else {
        let sizeProps =
          emote.width && emote.height
            ? { width: emote.width, height: emote.height }
            : null;
        return (
          <Emote
            key={match + i}
            src={emote.imgurl}
            shorthand={emote.shorthand}
            {...sizeProps}
          />
          // return <ThumbnailImage key={match + i} src={emoteUrl} />;
        );
      }
    });
  }

  return replacedText;
}

function highlightText(text, highlightStr) {
  let replacedText;
  replacedText = text;

  const regex = new RegExp("(" + escapeRegExp(highlightStr) + ")", "i");

  if (highlightStr) {
    // highlight search string
    replacedText = reactStringReplace(replacedText, regex, (match, i) => {
      // return <span key={match + i}>abc {match} def</span>;
      return (
        <>
          <span
            style={{
              backgroundColor: "#FFFF00",
              verticalAlign: "top",
            }}
          >
            {match}
          </span>
        </>
      );
      // return <ThumbnailImage key={match + i} src={emoteUrl} />;
    });
  }

  return replacedText;
}

export { bbcode, emotes, highlightText };
