import React, { useContext, useState, useEffect } from "react";
import { useSocket } from "./SocketProvider";

const UsersContext = React.createContext();

export function useUsers() {
  return useContext(UsersContext);
}

export function UsersProvider({ children }) {
  const [users, setUsers] = useState([]);
  const socket = useSocket();

  useEffect(() => {
    if (socket == null) return;
    socket.on("users-update", setUsers);
    return () => socket.off("users-update");
  }, [socket]);

  const value = { users, setUsers };
  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
}
