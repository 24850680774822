import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { emotes } from "../functions/bbcode";

function EmotePickerButton({
  insertAtCursor,
  showEmotes,
  setShowEmotes,
  handleShowEmotes,
  handleCloseEmotes,
  children,
  ...props
}) {
  return (
    <>
      <Button
        onClick={!showEmotes ? handleShowEmotes : handleCloseEmotes}
        {...props}
      >
        {children}
      </Button>
      <Offcanvas
        id="emote-offcanvas"
        show={showEmotes}
        onHide={handleCloseEmotes}
        placement={window.innerWidth < 960 ? "top" : "end"}
        style={window.innerWidth < 960 ? { height: "80vh" } : {}}
        backdrop={false}
        scroll={true}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Available Emotes</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            {emotes.map((emote, idx) => (
              <li
                onClick={(e) => insertAtCursor(emote.shorthand)}
                style={{ cursor: "pointer" }}
                key={idx}
              >
                <img
                  style={{ maxHeight: "50px" }}
                  src={emote.imgurl}
                  alt={`click to insert emote ${emote.shorthand}`}
                />
                {emote.shorthand}
              </li>
            ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default EmotePickerButton;
