import React from "react";
import {
  BsPaintBucket,
  BsFillShieldLockFill,
  BsQuestionCircleFill,
  BsAppIndicator,
  BsBell,
} from "react-icons/bs";
import PushSubscriptionButton from "./Settings/PushSubscriptionButton";
import DarkModeSwitch from "./Settings/DarkModeSwitch";
import AppInstallationInstructions from "./Settings/AppInstallationInstructions";
import AppUpdateButton from "./Settings/AppUpdateButton";
import ChangePasswordForm from "./Settings/ChangePasswordForm";

function Settings({ token }) {
  return (
    <div id="wrapping-container">
      <h2>
        <BsBell /> Notifications
      </h2>
      <PushSubscriptionButton token={token} />
      <hr />
      <h2>
        <BsPaintBucket /> Appearance
      </h2>{" "}
      <DarkModeSwitch />
      <hr />
      <h2>
        <BsQuestionCircleFill /> How to install the App
      </h2>
      <AppInstallationInstructions />
      <hr />
      <h2>
        <BsAppIndicator /> Update the App
      </h2>
      <AppUpdateButton />
      <hr />
      <h2>
        <BsFillShieldLockFill /> Change Password
      </h2>
      <ChangePasswordForm token={token} />
    </div>
  );
}

export default Settings;
